import axiosIns from '@/libs/axios'
import { objectToUrlParam } from '@/libs/helper'

export default class TradecopyApis {
  getAll = async data => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/trade-copy${objectToUrlParam(data)}`);

  getAgreements = async () => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/trade-copy/agreements`);

  getAgreementContent = async id => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/trade-copy/agreement/${id}`);

  getAccounts = async () => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/trade-copy/master/platform/accounts`);

  register = async data => await axiosIns.post(`${process.env.VUE_APP_FOREX_BASE_API_URL}/trade-copy/register`, data);

  getUserAccount = async () => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/trade-copy/master/account`);

  followAccount = async data => await axiosIns.post(`${process.env.VUE_APP_FOREX_BASE_API_URL}/trade-copy/follow`, data);

  getMasters = async data => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/trade-copy/masters${objectToUrlParam(data)}`);

  getSlaves = async data => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/trade-copy/slaves${objectToUrlParam(data)}`);

  unfollowAccount = async data => await axiosIns.post(`${process.env.VUE_APP_FOREX_BASE_API_URL}/trade-copy/unfollow`, data);
}
