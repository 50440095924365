<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card :title="$t('following_accounts')">
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          {{ $t('following_accounts_description') }}
        </b-col>
        <b-col
          v-if="masters.length > 0"
          cols="12"
        >
          <b-overlay
            :show="mastersShow"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <b-row>
              <b-col
                v-for="item in masters"
                :key="item.master.id"
                cols="12"
                sm="4"
                md="3"
              >
                <b-card
                  bg-variant="light"
                >
                  <b-row
                    class="text-center my-1"
                  >
                    <b-col cols="6">
                      {{ $t('strategy') }}
                    </b-col>
                    <b-col cols="6">
                      {{ item.master.strategy_name }}
                    </b-col>
                  </b-row>
                  <b-row
                    class="text-center my-1"
                  >
                    <b-col cols="6">
                      {{ $t('copytrade_info.group') }}
                    </b-col>
                    <b-col cols="6">
                      {{ item.master.account.group }}
                    </b-col>
                  </b-row>
                  <b-row
                    class="text-center my-1"
                  >
                    <b-col cols="6">
                      {{ $t('copytrade_info.platform') }}
                    </b-col>
                    <b-col cols="6">
                      {{ item.master.account.platform }}
                    </b-col>
                  </b-row>
                  <b-row
                    align-h="center"
                    class="mt-1"
                  >
                    <b-col cols="12">
                      <b-button
                        block
                        size="sm"
                        variant="info"
                        @click="unfollowAccount(item.slave.account.id)"
                      >
                        {{ $t('cancel') }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-overlay>
          <b-row
            v-if="masterPagination.total > masterPagination.pe_page"
            align-h="center"
            class="mt-2"
          >
            <b-col cols="auto">
              <b-pagination
                v-model="masterPagination.page"
                :total-rows="masterPagination.total"
                :per-page="masterPagination.per_page"
                size="sm"
                @input="changeMasterPage()"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-else
          class="text-center my-2"
        >
          <h6 class="text-danger">
            {{ $t('no_following_exists') }}
          </h6>
        </b-col>
        <b-col
          cols="12"
          class="d-flex justify-content-center"
        >
          <router-link :to="{name:'copytrade_list'}">
            <b-button variant="success">
              {{ $t('slave_accounts_list') }}
            </b-button>
          </router-link>
        </b-col>
      </b-row>
    </b-card>
    <b-card :title="$t('followers_accounts')">
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          {{ $t('followers_accounts_description') }}
        </b-col>
        <b-col
          v-if="slaves.length > 0"
          cols="12"
        >
          <b-overlay
            :show="slavesShow"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <b-row>
              <b-col
                v-for="slave in slaves"
                :key="slave.id"
                cols="12"
                sm="4"
                md="3"
              >
                <b-card
                  bg-variant="light"
                >
                  <b-row
                    class="text-center my-1"
                  >
                    <b-col cols="6">
                      {{ $t('id') }}
                    </b-col>
                    <b-col cols="6">
                      {{ slave.id }}
                    </b-col>
                  </b-row>
                  <b-row
                    class="text-center my-1"
                  >
                    <b-col cols="6">
                      {{ $t('account') }}
                    </b-col>
                    <b-col cols="6">
                      {{ slave.account.account }}
                    </b-col>
                  </b-row>
                  <b-row
                    class="text-center my-1"
                  >
                    <b-col cols="6">
                      {{ $t('copytrade_info.group') }}
                    </b-col>
                    <b-col cols="6">
                      {{ slave.account.group }}
                    </b-col>
                  </b-row>
                  <b-row
                    class="text-center my-1"
                  >
                    <b-col cols="6">
                      {{ $t('copytrade_info.platform') }}
                    </b-col>
                    <b-col cols="6">
                      {{ slave.account.platform }}
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-overlay>
          <b-row
            v-if="slavePagination.total > slavePagination.pe_page"
            align-h="center"
            class="mt-2"
          >
            <b-col cols="auto">
              <b-pagination
                v-model="slavePagination.page"
                :total-rows="slavePagination.total"
                :per-page="slavePagination.per_page"
                size="sm"
                @input="changeSlavePage()"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-else
          class="text-center my-2"
        >
          <h6 class="text-danger">
            {{ $t('no_followers_exists') }}
          </h6>
        </b-col>
        <b-col
          cols="12"
          class="d-flex justify-content-center"
        >
          <router-link :to="{name:'copytrade_create'}">
            <b-button variant="success">
              {{ $t('master_accounts_create') }}
            </b-button>
          </router-link>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BPagination,
  BButton,
} from 'bootstrap-vue'
import TradecopyApis from '@/modules/copytrade/service/apis/index'

const tradecopyApis = new TradecopyApis()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BPagination,
    BButton,
  },
  data() {
    return {
      show: false,
      masters: [],
      slaves: [],
      masterPagination: {
        per_page: 2,
        total: 0,
        page: 1,
      },
      slavePagination: {
        per_page: 3,
        total: 0,
        page: 1,
      },
      slavesShow: false,
      mastersShow: false,
    }
  },
  mounted() {
    this.getMasters()
    this.getSlaves()
  },
  methods: {
    getMasters() {
      this.mastersShow = true
      const { total, ...pagination } = this.masterPagination
      tradecopyApis.getMasters(pagination).then(res => {
        this.masters = res.data.results.data
        this.masterPagination.total = res.data.results.pagination.total
      }).finally(() => {
        this.mastersShow = false
      })
    },
    getSlaves() {
      this.slavesShow = true
      const { total, ...pagination } = this.slavePagination
      tradecopyApis.getSlaves(pagination).then(res => {
        this.slaves = res.data.results.data.map(item => item.slave)
        this.slavePagination.total = res.data.results.pagination.total
      }).finally(() => {
        this.slavesShow = false
      })
    },
    changeMasterPage() {
      this.getMasters()
    },
    changeSlavePage() {
      this.getSlaves()
    },
    unfollowAccount(id) {
      this.show = true
      tradecopyApis.unfollowAccount({
        account_id: id,
      }).then(res => {
        this.getMasters()
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
      }).finally(() => {
        this.show = false
      })
    },
  },
}
</script>

<style scoped>

</style>
